
import { defineComponent, computed, PropType } from "vue";

import Scale from "./scale.vue";
import Graph from "./graph.vue";
import { lensDataSet } from "@/utils/lens";
import { MyLensData } from "@/utils/data";

export default defineComponent({
  components: {
    Scale,
    Graph,
  },
  props: {
    lensList: {
      type: Array as PropType<MyLensData[]>,
      required: true,
    },
  },
  setup(props) {
    const scales = [5, 10, 24, 35, 50, 70, 105, 200, 400, 800, 1500];
    const scalesArray = scales.reduce((tmp, v, k) => {
      if (scales[k + 1]) {
        tmp.push([scales[k], scales[k + 1]]);
      }
      return tmp;
    }, [] as number[][]);

    const lensListPerYear = computed(() => {
      return props.lensList.reduce(
        (tmp, a) => {
          const year = a.data.buyDate.split("-")[0];
          if (!tmp[year]) {
            tmp[year] = [];
          }
          tmp[year].push(a);
          return tmp;
        },
        {} as { [key: string]: MyLensData[] },
      );
    });
    const years = computed(() => Object.keys(lensListPerYear.value));

    return {
      scalesArray,
      scales,
      lensDataSet,

      lensListPerYear,
      years,
    };
  },
});
