
import { defineComponent } from "vue";
import { requireLogin } from "@/utils/utils";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    requireLogin();

    return {
      store,
    };
  },
});
