
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  setup(_, ctx) {
    const update = (value: string) => {
      ctx.emit("update:modelValue", value);
    };
    return {
      update,
    };
  },
});
