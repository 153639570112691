import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-right my-auto" }
const _hoisted_2 = { class: "text-left ml-4" }
const _hoisted_3 = { class: "inline-flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lensList, (lensData, k) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "grid grid-cols-2 mt-2",
      key: k
    }, [
      _createElementVNode("div", _hoisted_1, "購入日: " + _toDisplayString(lensData.data.buyDate), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: _ctx.localizedUrl(lensData.lens.makerPath)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(lensData.lens.data.maker), 1)
            ]),
            _: 2
          }, 1032, ["to"]),
          _createTextVNode(" / "),
          _createVNode(_component_router_link, {
            to: _ctx.localizedUrl(lensData.lens.path)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(lensData.lens.name), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ])
      ])
    ]))
  }), 128))
}