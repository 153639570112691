
import { defineComponent, PropType } from "vue";
import { MyLensData } from "@/utils/data";

export default defineComponent({
  props: {
    lensList: {
      type: Array as PropType<MyLensData[]>,
      required: true,
    },
  },
});
