
import { defineComponent } from "vue";
import { googleSignin } from "@/utils/SocialLogin";
import { noLoginPage, useLocalizedRoute } from "@/utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "AccountPage",
  setup() {
    const route = useRoute();
    noLoginPage("/mypage");
    const routePush = useLocalizedRoute();
    console.log(route.query);
    return {
      googleSignin: googleSignin(
        () => {
          if (
            route.query &&
            route.query.to &&
            /^\/([a-zA-Z0-9_\-/]*)$/.test(route.query.to.toString())
          ) {
            routePush(route.query.to.toString());
          } else {
            routePush("/mypage");
          }
        },
        (err) => {
          alert(err.message);
        },
      ),
    };
  },
});
