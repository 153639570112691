
import { defineComponent, computed, PropType, ref, watch } from "vue";
import { User } from "firebase/auth";

import { useUserLens, useListenDbUser } from "./utils";

import DisplayMyInfo from "@/views/MyPage/MyInfo/Display.vue";
import EditMyInfo from "@/views/MyPage/MyInfo/Edit.vue";

import MyLensList from "@/views/MyPage/MyLensList.vue";
import FocalLength from "@/views/MyPage/FocalLength.vue";

import Tab from "./Tab.vue";
import { useLocalizedRoute } from "@/utils/utils";
import { useRoute } from "vue-router";

import { tabs } from "@/utils/data";

import { STwitter } from 'vue-socials'


export default defineComponent({
  components: {
    MyLensList,
    FocalLength,
    Tab,
    DisplayMyInfo,
    EditMyInfo,
    STwitter,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props) {
    const routePush = useLocalizedRoute();
    const route = useRoute();

    const uid = computed(() => props.user.uid);
    const { lensList } = useUserLens(uid);
    const dbUser = useListenDbUser(uid);

    const editMode = ref(false);

    const selectedTab = ref(route.params.viewType || tabs[0].dataType);
    watch(selectedTab, () => {
      routePush(`/mypage/viewType/${selectedTab.value}`);
    });
    return {
      lensList,
      tabs,
      selectedTab,
      editMode,
      dbUser,
    };
  },
});
