
import { defineComponent, ref, PropType } from "vue";
import { MyLensData } from "@/utils/data";

import Modal from "@/components/Modal.vue";

import { deleteDoc, doc } from "firebase/firestore";

import { db } from "@/utils/firebase";

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    lensList: {
      type: Array as PropType<MyLensData[]>,
      required: true,
    },
  },
  setup() {
    const willDelete = ref();
    const openModal = (v: MyLensData) => {
      console.log(v);
      willDelete.value = v;
    };
    const deleteData = async () => {
      const { uid, userLensId } = willDelete.value.data;
      const path = `users/${uid}/userLens/${userLensId}`;
      await deleteDoc(doc(db, path));
      willDelete.value = null;
    };
    return {
      openModal,
      willDelete,
      deleteData,
    };
  },
});
