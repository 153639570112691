import database from "lens-database";
import { Maker } from "lens-database";

import { DocumentData } from "firebase/firestore";
import { LensData } from "lens-database";

export const makers = Object.keys(database) as Maker[];
export const data = database;

export type MyLensData = {
  data: DocumentData;
  lens: LensData;
};

export const tabs = [
  { dataType: "list", name: "データ" },
  { dataType: "len", name: "焦点距離" },
];
