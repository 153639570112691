import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createTextVNode(" Makers List "),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.makers, (maker, k) => {
      return (_openBlock(), _createElementBlock("div", { key: k }, [
        _createVNode(_component_router_link, {
          to: _ctx.localizedUrl('/lenses/maker/' + maker)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(maker), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}