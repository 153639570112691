import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.userLens.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "You data"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userLens, (lens, k) => {
      return (_openBlock(), _createElementBlock("div", { key: k }, "購入日: " + _toDisplayString(lens.buyDate), 1))
    }), 128))
  ], 64))
}