
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    scales: {
      type: Array<number>,
      required: true,
    },
    lensData: {
      type: Object,
      required: true,
    },
  },
  setup(p) {
    const [mode, classes] = (() => {
      const [scaleLeft, scaleRight, lensLeft, lensRight] = [
        p.scales[0],
        p.scales[1],
        p.lensData.focalLength[0],
        p.lensData.focalLength[1],
      ];
      if (p.lensData.focalLength.length === 1) {
        console.log("single");
        if (scaleLeft <= lensLeft && lensLeft < scaleRight) {
          const ratio = Math.round(
            ((lensLeft - scaleLeft) / (scaleRight - scaleLeft)) * 100,
          );
          return ["pin", `width: ${ratio}%`];
        }
      }
      if (p.lensData.focalLength.length === 2) {
        console.log("zoom");
        if (lensLeft <= scaleLeft && scaleRight <= lensRight) {
          return ["whole"];
        }
        if (scaleLeft <= lensLeft && lensRight <= scaleRight) {
          const base = scaleRight - scaleLeft;
          console.log(scaleLeft, scaleRight, lensLeft, lensRight);
          const w = [
            `width: ${Math.round(((lensLeft - scaleLeft) / base) * 100)}%`,
            `width: ${Math.round(((lensRight - lensLeft) / base) * 100)}%`,
            `width: ${Math.round(((scaleRight - lensRight) / base) * 100)}%`,
          ];
          return ["in", w];
        }
        // case left
        if (
          lensLeft <= scaleLeft &&
          scaleLeft <= lensRight &&
          lensRight <= scaleRight
        ) {
          if (scaleRight === scaleLeft) {
            return ["none"];
          }
          console.log("left");
          const ratio =
            ((lensRight - scaleLeft) / (scaleRight - scaleLeft)) * 100;
          // console.log(scaleLeft, scaleRight, lensRight)
          return ["left", `width: ${Math.round(ratio)}%`];
        }
        // case right
        if (
          scaleLeft <= lensLeft &&
          lensLeft <= scaleRight &&
          scaleRight <= lensRight
        ) {
          if (lensLeft === scaleRight) {
            return ["none"];
          }
          const ratio =
            ((scaleRight - lensLeft) / (scaleRight - scaleLeft)) * 100;
          console.log("right");
          return ["right", `width: ${Math.round(ratio)}%`];
        }
      }
      return ["none"];
    })();
    // console.log(mode);
    return {
      mode,
      classes,
    };
  },
});
