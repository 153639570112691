
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { auth } from "@/utils/firebase";
import { signOut } from "firebase/auth";

import { useLang } from "@/utils/utils";

export default defineComponent({
  emits: ["closeMenu"],
  setup(_, ctx) {
    const store = useStore();
    const { localizedUrl } = useLang();

    const handleClose = () => {
      ctx.emit("closeMenu");
    };
    const logout = () => {
      signOut(auth);
      ctx.emit("closeMenu");
    };
    return {
      handleClose,
      logout,
      localizedUrl,
      store,
    };
  },
});
