
import { defineComponent, ref } from "vue";

import { getDoc, doc, setDoc } from "firebase/firestore";

import { db } from "@/utils/firebase";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, ctx) {
    const store = useStore();

    const path = `users/${store.state.user.uid}`;
    const userName = ref("");
    const description = ref("");

    getDoc(doc(db, path)).then((a) => {
      const data = a.data() || {};
      userName.value = data.userName || "";
      description.value = data.description || "";
    });

    const update = async () => {
      await setDoc(
        doc(db, path),
        {
          userName: userName.value,
          description: description.value,
        },
        { merge: true },
      );
      ctx.emit("update:modelValue", false);
    };
    return {
      update,
      userName,
      description,
    };
  },
});
