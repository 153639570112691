
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    dbUser: {
      type: Object,
      required: false,
    },
  },
});
