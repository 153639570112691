
import {
  defineComponent,
  ref,
  computed,
  onUnmounted,
  watch,
} from "vue";
import {
  collectionGroup,
  onSnapshot,
  query,
  where,
  DocumentData,
  Unsubscribe,
  getDocs,
  collection,
  documentId,
} from "firebase/firestore";

import { db } from "@/utils/firebase";
import { useUserObj, useDetacher } from "@/utils/utils";

export default defineComponent({
  props: {
    lensId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const userLens = ref<DocumentData[]>([]);
    const {
      detachers,
      detach,
    } = useDetacher();

    const lensId = computed(() => props.lensId);
    watch(
      lensId,
      () => {
        detach();
        const d = onSnapshot(
          query(
            collectionGroup(db, "userLens"),
            where("lensId", "==", lensId.value),
            where("isPublic", "==", true),
          ),
          (res) => {
            userLens.value = res.docs.map((a) => a.data());
          },
        );
        detachers.push(d);
      },
      { immediate: true },
    );

    const userIds = computed(() => {
      return userLens.value.map(a => a.uid);
    });
    const {
      userObj,
      getUsers,
    } = useUserObj();
    watch(userIds, () => {
      getUsers(userIds.value);
    });

    return {
      userLens,
      userObj,
    };
  },
});
