import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-4 bg-blue-100 py-4 rounded-lg" }
const _hoisted_2 = {
  key: 0,
  class: "text-3xl border-2 border-blue-400 w-48 rounded-lg bg-blue-200 p-4 m-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userWant)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 欲しい!! "))
      : _createCommentVNode("", true),
    (!_ctx.userWant)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "border-2 border-blue-400 w-48 p-4 rounded-lg bg-blue-200 hover:bg-blue-600 hover:text-white mt-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addLens && _ctx.addLens(...args)))
        }, " 欲しい！ "))
      : _createCommentVNode("", true),
    (_ctx.userWant)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "border-2 border-blue-400 w-40 p-1 rounded-lg bg-blue-200 hover:bg-blue-600 hover:text-white mt-4 text-xs",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeLens && _ctx.removeLens(...args)))
        }, " 欲しいを取り消す！ "))
      : _createCommentVNode("", true)
  ]))
}