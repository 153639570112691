import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "@/components/Layout.vue";
import Blank from "@/components/Blank.vue";
import NotFound from "@/components/NotFound.vue";

import Home from "@/views/Home.vue";
import Account from "@/views/Account.vue";

import MyPageUser from "@/views/MyPage/User.vue";
import MyPageWrapper from "@/views/MyPage/MyPageWrapper.vue";

import Test from "@/views/Test.vue";
import User from "@/views/user/User.vue";

import Lens from "@/views/lens/Lens.vue";
import LensList from "@/views/lens/LensList.vue";
import LensMakerList from "@/views/lens/LensMakerList.vue";

const routeChildren: Array<RouteRecordRaw> = [
  {
    path: "",
    component: Home,
  },
  {
    path: "users/:userId",
    component: User,
    props: true,
  },
  {
    path: "users/:userId/viewtype/:viewType",
    component: User,
    props: true,
  },
  {
    path: "account",
    component: Account,
  },
  {
    path: "mypage",
    component: MyPageWrapper,
    children: [
      {
        path: "",
        component: MyPageUser,
      },
      {
        path: "viewtype/:viewType",
        component: MyPageUser,
      },
    ],
  },
  {
    path: "lenses/makers",
    component: LensMakerList,
  },
  {
    path: "lenses/maker/:makerId",
    component: LensList,
    props: true,
  },
  {
    path: "lenses/maker/:makerId/lens/:lensId",
    component: Lens,
    props: true,
  },
  {
    path: "test",
    component: Test,
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/:lang",
        component: Blank,
        children: routeChildren,
      },
      {
        path: "",
        component: Blank,
        children: routeChildren,
      },
    ],
  },
  {
    path: "/:page(.*)",
    name: "NotFoundPage",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
