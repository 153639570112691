
import {
  defineComponent,
  ref,
  computed,
  onUnmounted,
  PropType,
  watch,
} from "vue";
import { User } from "firebase/auth";
import {
  collection,
  onSnapshot,
  query,
  where,
  DocumentData,
  Unsubscribe,
} from "firebase/firestore";

import { db } from "@/utils/firebase";

import { useDetacher } from "@/utils/utils";                                                                                            

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    lensId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const path = `users/${props.user.uid}/userLens`;

    const userLens = ref<DocumentData[]>([]);
    const {
      detachers,
      detach,
    } = useDetacher();
    const lensId = computed(() => props.lensId);
    watch(
      lensId,
      () => {
        detach();
        const d = onSnapshot(
          query(collection(db, path), where("lensId", "==", lensId.value)),
          (res) => {
            userLens.value = res.docs.map((a) => a.data());
          },
        );
        detachers.push(d)
      },
      {
        immediate: true,
      },
    );

    return {
      userLens,
    };
  },
});
