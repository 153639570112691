import languages from "./languages";

const lang = {
  message: {
    hello: "こんにちは、世界",
  },
  languages,
  camera: {},
  lens: {
    maker: "メーカー",
    name: "レンズ名",
    mount: "マウント",
    EANCode: "JAN",
    focalLength: "焦点距離",
    hasStabilizer: "手振れ補正",
    hasDustMoistureResistance: "防塵",
    fStop: "開放F値",
    format: "サイズ",
    focus: "フォーカス",
    filterDiameter: "フィルター径",
    weight: "重さ",
    releaseDate: "発売日",
  },
  company: {
    canon: "CANON(キャノン)",
  },
  yes: "あり",
};

export default lang;
