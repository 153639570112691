
import { defineComponent } from "vue";
import UserInfo from "./UserInfo.vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    dbUser: {
      type: Object,
      required: false,
    },
  },
  components: {
    UserInfo,
  },
  setup(_, ctx) {
    const update = () => {
      ctx.emit("update:modelValue", true);
    };
    return {
      update,
    };
  },
});
