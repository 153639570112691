import { ref, computed, watch, onUnmounted, ComputedRef } from "vue";
import {
  collection,
  onSnapshot,
  getDoc,
  doc,
  query,
  DocumentData,
} from "firebase/firestore";

import { db } from "@/utils/firebase";

import { allLensData } from "lens-database";
import Lens from "@/models/lens";

import { useDetacher } from "@/utils/utils";

export const useUserLens = (uid: ComputedRef<string>) => {
  const {
    detachers,
    detach,
  } = useDetacher();
  

  const userLens = ref<DocumentData[]>([]);
  watch(uid, () => {
    const path = `users/${uid.value}/userLens`;
    detach();
    const d = onSnapshot(query(collection(db, path)), (res) => {
      userLens.value = res.docs.map((a) => a.data());
    });
    detachers.push(d);
  }, { immediate: true });
  const lensList = computed(() => {
    return userLens.value.map((data) => {
      return {
        lens: new Lens(allLensData[data.lensId]),
        data: data,
      };
    });
  });

  const sortedLlensList = computed(() => {
    const list = [...lensList.value];
    return list.sort((a, b) => {
      return Number(a.data.buyDate.replace(/-/g, "")) >
        Number(b.data.buyDate.replace(/-/g, ""))
        ? 1
        : -1;
    });
  });

  return {
    lensList: sortedLlensList,
  };
};

export const useListenDbUser = (uid: ComputedRef<string>) => {
  const {
    detachers,
    detach,
  } = useDetacher();

  const dbUser = ref();
  watch(uid, () => {
    detach();
    const path = `users/${uid.value}`;
    dbUser.value = undefined;
    const d = onSnapshot(doc(db, path),
                         (a) => {
                           dbUser.value = a.data();
                         });
    detachers.push(d);
  }, { immediate: true });
  return dbUser;
};
