import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2" }
const _hoisted_2 = { class: "mt-6 mx-4 px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_LensList = _resolveComponent("LensList")!
  const _component_FocalLength = _resolveComponent("FocalLength")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UserInfo, { dbUser: _ctx.dbUser }, null, 8, ["dbUser"])
    ]),
    _createVNode(_component_Tab, {
      tabs: _ctx.tabs,
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event))
    }, null, 8, ["tabs", "modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.selectedTab === 'list')
        ? (_openBlock(), _createBlock(_component_LensList, {
            key: 0,
            lensList: _ctx.lensList
          }, null, 8, ["lensList"]))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 'len')
        ? (_openBlock(), _createBlock(_component_FocalLength, {
            key: 1,
            lensList: _ctx.lensList
          }, null, 8, ["lensList"]))
        : _createCommentVNode("", true)
    ])
  ]))
}