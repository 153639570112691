
import { defineComponent, computed } from "vue";
import { data } from "@/utils/data";
import Lens from "@/models/lens";

export default defineComponent({
  name: "HomePage",
  components: {},
  setup() {
    const lenses = computed(() => {
      return Object.values(data)
        .map((a) => {
          return Object.values(a);
        })
        .flat()
        .flat()
        .map((l) => new Lens(l));
    });
    return {
      lenses,
    };
  },
});
