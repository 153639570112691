
import { defineComponent, computed, PropType, ref, watch } from "vue";
import { useListenDbUser, useUserLens } from "../MyPage/utils";
import UserInfo from "@/views/MyPage/MyInfo/UserInfo.vue";
import { useRoute } from "vue-router";

import Tab from "../MyPage/Tab.vue";
import LensList from "@/views/user/LensList.vue";
import FocalLength from "@/views/MyPage/FocalLength.vue";

import { tabs } from "@/utils/data";
import { useLocalizedRoute } from "@/utils/utils";

export default defineComponent({
  components: {
    UserInfo,
    LensList,
    FocalLength,
    Tab,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const routePush = useLocalizedRoute();
    const userId = computed(() => props.userId);

    const { lensList } = useUserLens(userId);
    const dbUser = useListenDbUser(userId);

    const selectedTab = ref(route.params.viewType || tabs[0].dataType);
    watch(selectedTab, () => {
      routePush(`/users/${userId.value}/viewType/${selectedTab.value}`);
    });

    return {
      tabs,
      selectedTab,

      dbUser,
      lensList,
    };
  }
});
