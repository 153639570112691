import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-9/12 m-auto mt-8" }
const _hoisted_2 = { class: "text-right" }
const _hoisted_3 = { class: "text-left ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LensAdd = _resolveComponent("LensAdd")!
  const _component_LensMyList = _resolveComponent("LensMyList")!
  const _component_LensWant = _resolveComponent("LensWant")!
  const _component_LensOwnerList = _resolveComponent("LensOwnerList")!
  const _component_LensWantList = _resolveComponent("LensWantList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.lens["maker"]) + " Lens", 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataKeys, (key, k) => {
        return (_openBlock(), _createElementBlock("div", {
          key: k,
          class: "grid grid-cols-2"
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("lens." + key)), 1),
          _createElementVNode("div", _hoisted_3, [
            (key === 'maker')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _ctx.localizedUrl(`/lenses/maker/${_ctx.makerId}`)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.lens[key]), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (key === 'focalLength')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.lens[key].join("-")) + "mm ", 1)
                  ], 64))
                : (
            ['hasStabilizer', 'hasDustMoistureResistance'].includes(key)
          )
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(_ctx.lens[key] ? _ctx.$t("yes") : ""), 1)
                    ], 64))
                  : (key === 'fStop')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        _createTextVNode(_toDisplayString(_ctx.lens[key].join("-")), 1)
                      ], 64))
                    : (key === 'focus')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                          _createTextVNode(_toDisplayString(_ctx.lens[key].join("/")), 1)
                        ], 64))
                      : (key === 'filterDiameter')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                            _createTextVNode(_toDisplayString(_ctx.lens[key]) + " mm ", 1)
                          ], 64))
                        : (key === 'weight')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                              _createTextVNode(_toDisplayString(_ctx.lens[key]) + " g ", 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                              _createTextVNode(_toDisplayString(_ctx.lens[key]), 1)
                            ], 64))
          ])
        ]))
      }), 128)),
      _createVNode(_component_LensAdd, { lensId: _ctx.lensId }, null, 8, ["lensId"]),
      (_ctx.store.state.user)
        ? (_openBlock(), _createBlock(_component_LensMyList, {
            key: 0,
            user: _ctx.store.state.user,
            lensId: _ctx.lensId
          }, null, 8, ["user", "lensId"]))
        : _createCommentVNode("", true),
      _createVNode(_component_LensWant, {
        user: _ctx.store.state.user,
        lensId: _ctx.lensId
      }, null, 8, ["user", "lensId"]),
      _createVNode(_component_LensOwnerList, { lensId: _ctx.lensId }, null, 8, ["lensId"]),
      _createVNode(_component_LensWantList, { lensId: _ctx.lensId }, null, 8, ["lensId"])
    ])
  ], 64))
}