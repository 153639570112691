
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRedirectToLoginToSelf } from "@/utils/utils";

import { db } from "@/utils/firebase";
import { doc, collection, setDoc, serverTimestamp } from "firebase/firestore";

export default defineComponent({
  props: {
    lensId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const redirectToLogin = useRedirectToLoginToSelf();

    const buyDate = ref();

    const addLens = async () => {
      if (!store.state.user) {
        redirectToLogin();
        return;
      }
      if (!buyDate.value) {
        return;
      }
      const uid = store.state.user.uid;
      const id = doc(collection(db, "test")).id;
      const path = `users/${uid}/userLens/${id}`;
      const data = {
        buyDate: buyDate.value,
        lensId: props.lensId,
        uid,
        isPublic: true,
        userLensId: id,
        created: serverTimestamp(),
      };
      // console.log(path, data);
      await setDoc(doc(db, path), data);
    };
    return {
      buyDate,
      addLens,
    };
  },
});
