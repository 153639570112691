import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.userLens.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "欲しい一覧"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userLens, (lens, k) => {
      return (_openBlock(), _createElementBlock("div", { key: k }, [
        _createVNode(_component_router_link, {
          to: _ctx.localizedUrl(`/users/${lens.uid}`)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(((_ctx.userObj || {} )[lens.uid]||{}).userName || "--"), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ], 64))
}