
import { defineComponent, PropType, computed } from "vue";
import { useStore } from "vuex";
import { data } from "@/utils/data";

import { Maker } from "lens-database";

import LensMyList from "./lens/MyList.vue";
import LensOwnerList from "./lens/OwnerList.vue";
import LensWantList from "./lens/WantList.vue";

import LensAdd from "./lens/add.vue";
import LensWant from "./lens/want.vue";

export default defineComponent({
  props: {
    makerId: {
      type: String as PropType<Maker>,
      required: true,
    },
    lensId: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: false,
    },
  },
  components: {
    LensMyList,

    LensOwnerList,
    LensWantList,
    
    LensAdd,
    LensWant,
  },
  setup(props) {
    const store = useStore();

    const lenses = computed(() => Object.values(data[props.makerId]).flat());

    const lens = lenses.value.find((l) => l.id === props.lensId);

    const dataKeys = [
      "maker",
      "name",
      "mount",
      "EANCode",
      "focalLength",
      "hasStabilizer",
      "hasDustMoistureResistance",
      "fStop",
      "format",
      "focus",
      "filterDiameter",
      "weight",
      "releaseDate",
    ];

    return {
      lens,
      dataKeys,
      store,
    };
  },
});
