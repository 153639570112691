const canonRFLensDataSet = [
  {
    id: "4549292148381",
    maker: "Canon",
    name: "RF24-70mm F2.8 L IS USM",
    mount: "RF",
    focalLength: [24, 70],
  },
  {
    id: "4549292156263",
    maker: "Canon",
    name: "RF70-200mm F2.8 L IS USM",
    mount: "RF",
    focalLength: [70, 200],
  },
  {
    maker: "Canon",
    name: "RF50mm F1.8 STM",
    mount: "RF",
    focalLength: [50],
  },
  {
    maker: "Canon",
    name: "RF15-35mm F2.8 L IS USM",
    mount: "RF",
    focalLength: [15, 35],
  },
  {
    maker: "Canon",
    name: "RF24-50mm F4.5-6.3 IS STM",
    mount: "RF",
    focalLength: [24, 50],
  },
  {
    maker: "Canon",
    name: "RF100-400mm F5.6-8 IS USM",
    mount: "RF",
    focalLength: [100, 400],
  },
];

const canonEFSLensDataSet = [
  {
    maker: "Canon",
    name: "EF-S18-55mm F4-5.6 IS STM",
    mount: "EF-S",
    focalLength: [18, 55],
  },
  {
    id: "4960999689524",
    maker: "Canon",
    name: "EF-S55-250mm F4-5.6 IS II",
    mount: "EF-S",
    focalLength: [55, 250],
  },
  {
    maker: "Canon",
    name: "EF-S18-135mm F3.5-5.6 IS USM",
    mount: "EF-S",
    focalLength: [18, 135],
  },
];
const canonEFLensDataSet = [
  {
    maker: "Canon",
    name: "CANON EF70-300mm F4-5.6 IS II USM",
    mount: "EF",
    focalLength: [70, 300],
  },
];

const hoge = [
  {
    maker: "Fujifilm",
    name: "XF10-24mmF4 R OIS WR",
    mount: "X",
    focalLength: [10, 24],
  },
  {
    id: "0085126585754",
    maker: "SIGMA",
    name: "18-50mm F2.8 DC DN",
    mount: "X",
    focalLength: [18, 50],
  },
  {
    maker: "Tokina",
    name: "AT-X 11-20 PRO DX CAF",
    mount: "EF-S",
    focalLength: [11, 20],
  },
];

export const lensDataSet = [
  ...canonRFLensDataSet,
  ...canonEFLensDataSet,
  ...canonEFSLensDataSet,
  ...hoge,
];
