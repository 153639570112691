import { ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import router from "@/router";

import { db } from "@/utils/firebase";
import { collection, where, documentId, query, getDocs, Unsubscribe, DocumentData } from "firebase/firestore";

export const useUser = () => {
  const store = useStore();
  const user = computed(() => store.state.user);
  return user;
};
export const useIsSignedIn = () => {
  const store = useStore();
  const isSignedIn = computed(() => store.getters.isSignedIn);
  return isSignedIn;
};

export const useLang = () => {
  const i18n = useI18n();

  const lang = computed(() => {
    return i18n.locale.value;
  });

  const localizedUrl = (path: string) => {
    if (lang.value) {
      return `/${lang.value}` + path;
    }
    return path;
  };
  const normalPath = (() => {
    const path = location.pathname;
    const prefix = `/${lang.value}`;
    if (path.startsWith(prefix)) {
      return path.slice(prefix.length);
    }
    return path;
  })();
  return {
    lang,
    localizedUrl,
    normalPath,
  };
};

export const sleep = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const useLocalizedRoute = () => {
  const { localizedUrl } = useLang();

  return (path: string) => {
    router.push(localizedUrl(path));
  };
};

export const noLoginPage = (path: string) => {
  const store = useStore();
  const routePush = useLocalizedRoute();

  const unwatch = store.watch(
    (state) => state.user,
    (user) => {
      if (user) {
        routePush(path);
      }
    },
    { immediate: true },
  );
  onUnmounted(() => {
    unwatch();
  });
};

export const useRedirectToLogin = (path?: string) => {
  const routePush = useLocalizedRoute();
  return () => {
    if (path) {
      routePush("/account?to=" + path);
    } else {
      routePush("/account");
    }
  };
};

export const useRedirectToLoginToSelf = () => {
  const { normalPath } = useLang();
  return useRedirectToLogin(normalPath);
};

export const requireLogin = (path?: string) => {
  const store = useStore();
  const redirectToLogin = useRedirectToLogin(path);
  const unwatch = store.watch(
    (state) => state.user,
    (user) => {
      if (user === null) {
        redirectToLogin();
      }
    },
    { immediate: true },
  );
  onUnmounted(() => {
    unwatch();
  });
};

export const useDetacher = () => {
  const detachers: Unsubscribe[] = [];
  const detach = () => {
    detachers.map((d) => {
      d();
    });
    detachers.length = 0;
  };
  onUnmounted(() => {
    detach();
  });
  return {
    detachers,
    detach,
  };
};

export const chunk = (arr: string[], chunkSize: number) => {
  const ret: string[][] = [];
  const len = arr.length;
  for (let i = 0; i < len; i += chunkSize) {
    const tmp = arr.slice(i, i + chunkSize);
    ret.push(tmp);
  }
  return ret;
};

export const useUserObj = () => {
  const userObj = ref<{ [key: string]: DocumentData }>({});
  const getUsers = async (uids: string[]) => {
    await Promise.all(
      chunk(uids, 10).map(async (ids) => {
        const userCollection = await getDocs(
          query(
            collection(db, "users"),
            where(documentId(), "in", ids),
          )
        )
        if (!userCollection.empty) {
          userCollection.docs.map((a) => {
            userObj.value[a.id] = a.data();
          });
        }
      })
    );
  };
  return {
    userObj,
    getUsers,
  };
};
