import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.makerId) + " Lens List", 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data[_ctx.makerId], (lensSet, mount) => {
      return (_openBlock(), _createElementBlock("div", {
        key: mount,
        class: "text-left w-80 m-auto"
      }, [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(mount) + " Mount", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lensSet, (lens, k) => {
          return (_openBlock(), _createElementBlock("div", { key: k }, [
            _createVNode(_component_router_link, {
              to: _ctx.localizedUrl(`/lenses/maker/${_ctx.makerId}/lens/${lens.id}`)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(lens.name), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ]))
    }), 128))
  ], 64))
}