import { LensData } from "lens-database";

export default class Lens {
  data: LensData;
  name: string;
  maker: string;

  path: string;
  makerPath: string;

  constructor(lensData: LensData) {
    this.data = lensData;
    this.name = lensData.name;
    this.maker = lensData.maker;
    this.makerPath = `/lenses/maker/${lensData.maker}`;
    this.path = `${this.makerPath}/lens/${lensData.id}`;
  }
}
