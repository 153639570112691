import languages from "./languages";

const lang = {
  message: {
    hello: "hello world",
  },
  languages,
  camera: {},
  lens: {
    maker: "Manufacturer",
    name: "Lens Name",
    mount: "Mount",
    EANCode: "EAN Code",
    focalLength: "Focus Length",
    hasStabilizer: "Stabilizer",
    hasDustMoistureResistance: "Dust Moisture Resistance",
    fStop: "F Stop",
    format: "Format",
    focus: "Focus",
    filterDiameter: "Filter Diameter",
    weight: "Weight",
    releaseDate: "Release Date",
  },
  company: {
    canon: "CANON",
  },
  yes: "Yes",
};

export default lang;
