import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-4 bg-blue-100 py-4 rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createTextVNode(" 購入日: "),
      _withDirectives(_createElementVNode("input", {
        type: "date",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.buyDate) = $event)),
        class: "rounded-lg p-2 mt-2"
      }, null, 512), [
        [_vModelText, _ctx.buyDate]
      ])
    ]),
    _createElementVNode("button", {
      class: "border-2 border-blue-400 w-48 p-4 rounded-lg bg-blue-200 hover:bg-blue-600 hover:text-white mt-2",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addLens && _ctx.addLens(...args)))
    }, " 持っている ")
  ]))
}