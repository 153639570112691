import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInfo = _resolveComponent("UserInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UserInfo, { dbUser: _ctx.dbUser }, null, 8, ["dbUser"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        class: "border-2 border-blue-400 w-28 p-4 rounded-lg bg-blue-200 hover:bg-blue-600 hover:text-white mt-2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args)))
      }, " 編集 ")
    ])
  ], 64))
}