
import { defineComponent } from "vue";

import Scale from "./scale.vue";
import Graph from "./graph.vue";
import { lensDataSet } from "../utils/lens";

export default defineComponent({
  components: {
    Scale,
    Graph,
  },

  setup() {
    const scales = [5, 10, 24, 35, 50, 70, 105, 200, 400, 800, 1500];
    const scalesArray = scales.reduce((tmp, v, k) => {
      if (scales[k + 1]) {
        tmp.push([scales[k], scales[k + 1]]);
      }
      return tmp;
    }, [] as number[][]);
    console.log(scalesArray);
    return {
      scalesArray,
      scales,
      lensDataSet,
    };
  },
});
