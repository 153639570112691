
import { defineComponent, computed, PropType } from "vue";
import { data } from "@/utils/data";
import { Maker } from "lens-database";

export default defineComponent({
  props: {
    makerId: {
      type: String as PropType<Maker>,
      required: true,
    },
    lang: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const lenses = computed(() => Object.values(data[props.makerId]));
    return {
      lenses,
      data,
    };
  },
});
