
import { defineComponent } from "vue";
import { makers } from "@/utils/data";

export default defineComponent({
  async setup() {
    return {
      makers,
    };
  },
});
