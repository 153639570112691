
import { defineComponent, ref, computed, watch, PropType } from "vue";
import { useRedirectToLoginToSelf, useDetacher } from "@/utils/utils";

import { db } from "@/utils/firebase";
import {
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
  serverTimestamp,
  DocumentData,
} from "firebase/firestore";

import { User } from "firebase/auth";

export default defineComponent({
  props: {
    lensId: {
      type: String,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: false,
    },
  },
  setup(props) {
    const redirectToLogin = useRedirectToLoginToSelf();

    // watch
    const lensId = computed(() => props.lensId);
    const user = computed(() => props.user);

    const { detachers, detach } = useDetacher();
    const userWant = ref<DocumentData | undefined>();
    watch(
      [lensId, user],
      () => {
        if (lensId.value && user.value) {
          detach();
          const uid = user.value.uid;
          const path = `users/${uid}/userWant/${lensId.value}`;
          const d = onSnapshot(doc(db, path), (res) => {
            if (res) {
              userWant.value = res.data();
            } else {
              userWant.value = undefined;
            }
          });
          detachers.push(d);
        }
      },
      { immediate: true },
    );

    // add
    const addLens = async () => {
      if (!props.user) {
        redirectToLogin();
        return;
      }
      const uid = props.user.uid;
      const path = `users/${uid}/userWant/${props.lensId}`;
      const data = {
        lensId: props.lensId,
        uid,
        isPublic: true,
        created: serverTimestamp(),
      };
      console.log(path, data);
      await setDoc(doc(db, path), data);
    };
    // add
    const removeLens = async () => {
      if (!props.user) {
        redirectToLogin();
        return;
      }
      const uid = props.user.uid;
      const path = `users/${uid}/userWant/${props.lensId}`;
      await deleteDoc(doc(db, path));
    };
    return {
      addLens,
      userWant,
      removeLens,
    };
  },
});
