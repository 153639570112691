
import { defineComponent, reactive, onMounted, ref } from "vue";
import { useStore } from "vuex";

import { auth } from "@/utils/firebase";
import { User } from "firebase/auth";

import { useI18nParam } from "@/i18n/utils";

// import Languages from "@/components/Languages.vue";
import MenuList from "@/components/MenuList.vue";

interface UserData {
  user: User | null;
}

export default defineComponent({
  name: "AppLayout",
  components: {
    // Languages,
    MenuList,
  },
  async setup() {
    const store = useStore();
    const user = reactive<UserData>({ user: null });

    const menu = ref(false);

    useI18nParam();

    onMounted(() => {
      auth.onAuthStateChanged((fbuser) => {
        if (fbuser) {
          console.log("authStateChanged:");
          user.user = fbuser;
          store.commit("setUser", fbuser);
        } else {
          store.commit("setUser", null);
        }
      });
    });

    const toggleMenu = () => {
      menu.value = !menu.value;
    };
    return {
      user,

      menu,
      toggleMenu,
    };
  },
});
